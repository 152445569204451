<template>
<div>
      <b-card class="w-100 mb-4" no-body>
            
                <b-card-body class="bg-light">
                <!-- part 2 start -->
                <h4 class="card-title">Circles Details</h4>
                </b-card-body>
                <b-row class="align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Fauji Circle </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="purchaseref"
                        type="text"
                        placeholder="Purchase Ref"
                        v-model="form.fauji"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> LU Circle </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="purchaseref"
                        type="text"
                        placeholder="Purchase Ref"
                        v-model="form.lu"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Molfix Circle </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="purchaseref"
                        type="text"
                        placeholder="Purchase Ref"
                        v-model="form.molfix"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Tapal Circle </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-input
                        id="purchaseref"
                        type="text"
                        placeholder="Purchase Ref"
                        v-model="form.tapal"
                    ></b-form-input>
                    </b-col>
                </b-row>
                <b-row class="align-items-center">
                    <b-col sm="3" class="text-left text-md-right py-3 border-right">
                    <label class="fw-medium mb-0" for="company"> Cut Off Date </label>
                    </b-col>
                    <b-col sm="9" class="py-2">
                    <b-form-datepicker
                        id="example3-datepicker"
                        v-model="form.codate"
                        :date-format-options="{
                        day: '2-digit',
                        month: 'short',
                        year: 'numeric',
                        }"
                        class="mb-2"
                    ></b-form-datepicker>
                    </b-col>
                </b-row>
                
    </b-card>
    
        <b-card class="w-100 mb-4" no-body>
              <div class="p-35 border-top">
                <div class="btn-grp text-right">
                    <b-button pill variant="primary" class="px-4" @click="save">Save</b-button>
                    <!--<b-button pill variant="danger" class="px-4">Cancel</b-button>-->
                </div>
            </div>
        </b-card>
</div>  
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: "settings",
  data: () => ({
    form: {
      fauji: null,
      lu: null,
      molfix: null,
      tapal: null,
      codate: null
    }
  }),
  mounted (){
    this.initCircles();
  },
  methods: {
    ...mapActions(["getCircles", "saveCircles"]),
    initCircles(){
      this.getCircles().then(res => {
        this.form.fauji = res[0].fauji;
        this.form.lu = res[0].lu;
        this.form.molfix = res[0].molfix;
        this.form.tapal = res[0].tapal;
        this.form.codate = res[0].date; 
      })
    },
    save(){
      let form = {
        fauji: parseFloat(this.form.fauji),
        lu: parseFloat(this.form.lu),
        molfix: parseFloat(this.form.molfix),
        tapal: parseFloat(this.form.tapal),
        codate: this.form.codate+" 16:00:00",
      }
      this.saveCircles(form).then(res => {
        console.log(res);
        // Create the toast
        this.$bvToast.toast('Successfully saved cutof date and circles!', {
          title: `Done`,
          variant: 'success',
          solid: true
        })
      })
    }
  }
};
</script>
